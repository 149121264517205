import { history }         from "@src/history";
import axios               from "axios";
import { apiErrorHandler } from '@utils/apiErrorHandler';
import TagManager from 'react-gtm-module'

function loginSuccessful(response, dispatch) {
   let user = response.data.data.user;

   dispatch({
      type: "FETCH_AUTHORIZED_USER_INFO",
      user: user
   });

   localStorage.setItem('user', JSON.stringify(user));
   localStorage.setItem('access_token', response.data.data.access_token);

   axios.defaults.headers.common = {Authorization: `Bearer ${response.data.data.access_token}`};

   let redirect = '/products';
   if (user.role.name === 'superAdmin') {
      redirect = "/admin/dashboard";
   } else if (user.role.name === 'adminManager') {
      redirect = "/admin/clients-and-ads";
   }
   history.push(redirect);
}


export const loginWithSwitchToClientToken = token => {
   return dispatch => {
      axios.post(`/api/auth/switch-to-client/${token}`)
           .then(response => {
              if (response.data) {
                 loginSuccessful(response, dispatch);
              }
           }).catch(errors => {
         console.log(errors);
      });
   };
};

export const loginWithJWTReset = user => {
   return dispatch => {
      dispatch({
         type: "RESET_LOGIN"
      });
   };
};


export const loginWithJWT = user => {
   return dispatch => {
      axios
         .post(`/api/auth/login`, {
            email   : user.email,
            password: user.password,
         })
         .then(response => {
            if (response.data) {
               loginSuccessful(response, dispatch);
            }
         })
         .catch(err => {
            dispatch(apiErrorHandler(err));
         });
   };
};

export const logoutWithJWT = () => {
   return dispatch => {
      axios
         .post(`/api/auth/logout`, {})
         .then(response => {
            localStorage.removeItem('user');
            localStorage.removeItem('access_token');
            dispatch({
               type: 'RESET_USER_OBJECT'
            });
            history.push('/');
         })
         .catch(err => {
            dispatch(apiErrorHandler(err));
         });
   };
};

export const changeRole = role => {
   return dispatch => dispatch({type: "CHANGE_ROLE", userRole: role});
};

export const restorePassword = email => {

   return dispatch => {
      axios
         .post(`/api/auth/reset-password/link`, {
            email: email.email,
         })
         .then(response => {

            if (response.data) {
               dispatch({
                  type : "RESTORE_PASSWORD",
                  reset: {email: email.email}
               });
               history.push('/');
            }
         })
         .catch(err => {
            dispatch(apiErrorHandler(err));
         });
   };
};

export const resetPassword = (data) => {

   return dispatch => {
      dispatch(setProcessing(true))
      axios
         .post(`/api/auth/reset-password/token`, data)
         .then(response => {
            if (response.data) {
               if(response.data.status == 'success'){
                  dispatch({
                     type : "RESET_PASSWORD",
                     reset: response.data
                  });
                  history.push('/login');
               }else{
                  dispatch(resetPasswordError(response.data))
               }

            }
            dispatch(setProcessing(false))
         })
         .catch(err => {
            dispatch(apiErrorHandler(err));
            dispatch(setProcessing(false))

         });
   };
};

export const setProcessing = (proc) => {
   return dispatch => {
      dispatch({
         type : "RESET_PASSWORD_PROCESSING",
         payload: proc
      });
   };

};

export const resetPasswordError = (data) => {
   return dispatch => {
      dispatch({
         type : "RESET_PASSWORD_ERROR",
         message: data.message
      });
   };

};

export const resetSendCodeError = () => {
   return dispatch => {
      dispatch({
         type: 'RESET_AUTHORIZED_CODE_SEND_ERROR',
      });
   }
}

export const sendConfirmationCode = phone => {
   let to_send_phone = phone.replace(/([ -.*+?^=!:${}()|\[\]\/\\])/g, "");
   return dispatch => {
      dispatch(resetSendCodeError());
      axios
          .post(`/api/auth/signup/send-sms`, {
             phone: to_send_phone
          })
          .then(response => {
             if (response.data) {
                // code: "sms_sent"
                // data: {send_again_available_in: 60}
                // send_again_available_in: 60
                // message: "Code sent"
                // status: "success"
               dispatch({
                  type: 'AUTHORIZED_CODE_SEND',
                  payload: {
                     timeout: response?.data?.data?.send_again_available_in,
                     status: response.data.status == 'success'
                  }
               })
             }
          })
          .catch(err => {
             dispatch({
                type: 'AUTHORIZED_CODE_SEND_ERROR',
                payload : err?.response?.data?.message || 'Error send code'
             });
          });
   };
}

export const resetSMSData = () => {
   return dispatch => {
      dispatch({
         type: 'RESET_AUTHORIZED_CODE_DATA'
      })
   }
}


export const signUp = user => {
   let phone = user.phone.replace(/([ -.*+?^=!:${}()|\[\]\/\\])/g, "");
   return dispatch => {
      let data = {
         fname                : user.fname,
         lname                : user.lname,
         email                : user.email,
         phone                : phone,
         smscode              : user.smscode,
         password             : user.password,
         password_confirmation: user.password_confirmation,
      }

      if(user?.partner_id){
         data.partner_id = user?.partner_id;
      }

      axios
         .post(`/api/auth/signup`, data)
         .then(response => {
            if (response.data) {
               let user = response.data.data.user;

               dispatch({
                  type: "FETCH_AUTHORIZED_USER_INFO",
                  user: user
               });

               localStorage.setItem('user', JSON.stringify(user));
               localStorage.setItem('access_token', response.data.data.access_token);

               axios.defaults.headers.common = {Authorization: `Bearer ${response.data.data.access_token}`};

               let redirect = '/registration_success';

               history.push(redirect);

            }
         })
         .catch(err => {
            dispatch(apiErrorHandler(err));
         });
   };
};
