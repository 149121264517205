import React, {useState} from "react"
import {
    DropdownMenu,
    DropdownItem,
    DropdownToggle, Dropdown,
} from "reactstrap"
import * as Icon from "react-feather"
import {connect, useDispatch, useSelector} from "react-redux";
import {logoutWithJWT} from '@store/actions/auth/loginActions';
import moment from 'moment';
import "./custom.scss";
import {history} from "@src/history"
import {useTranslation} from "react-i18next";

const UserDropdown = props => {
    const { t, i18n } = useTranslation();


    return (
        <DropdownMenu right className={'user-dropdown-items'}>

            <DropdownItem
                tag="a"
                className="information-option no-hover"
            >
                <span>{t("dashboard.Plan")}:</span>
                <span className="reviews-count">{props.user.plan === null ? '-' : props.user.plan}</span>
                {props.user?.plan_status == 'active' && <span className='unsubscribe-btn' onClick={() => {props.confirm()}}>{t("Unsubscribe l")}</span>}

            </DropdownItem>
            {props.user.plan !== null && <DropdownItem
                tag="a"
                className="information-option no-hover"
            >
                <span>{t("dashboard.Expire")}:</span>
                <span className="reviews-count">{moment(props.user.plan_expire_at).format('DD-MM-YYYY')}</span>
            </DropdownItem>}
            <DropdownItem
                tag="a"
                className="information-option no-hover"
            >
                <span>{t("dashboard.Free Reviews")}:</span>
                <span className="reviews-count">{props?.user?.reviews || 0}</span>
            </DropdownItem>

                <DropdownItem
                    tag="a"
                    className="information-option no-hover"
                >
                    <span>{t("Free Votes")}:</span>
                    <span className="reviews-count">{props?.user?.votes || 0}</span>
                </DropdownItem>

            <DropdownItem
                tag="a"
                className="information-option no-hover"
            >
                <span>{t("Free Sales")}:</span>
                <span className="reviews-count">{props?.user?.sales || 0}</span>
            </DropdownItem>
            <DropdownItem
                tag="a"
                className="information-option no-hover"
            >
                <span>{t("dashboard.Balance")}:</span>
                <span className="reviews-count">{props.user.wallet}€</span>
            </DropdownItem>
            <DropdownItem divider className="b1"/>
            <DropdownItem
                tag="a"
                href="/profile"
                onClick={e => {
                    e.preventDefault();
                    history.push('/profile')
                }}
            >
                <svg width="12" height="15" viewBox="0 0 12 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M11 14V12.6667C11 11.9594 10.719 11.2811 10.219 10.781C9.71885 10.281 9.04058 10 8.33333 10H3.66667C2.95942 10 2.28115 10.281 1.78105 10.781C1.28095 11.2811 1 11.9594 1 12.6667V14" stroke="#5C7279" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M6 7C7.65685 7 9 5.65685 9 4C9 2.34315 7.65685 1 6 1C4.34315 1 3 2.34315 3 4C3 5.65685 4.34315 7 6 7Z" stroke="#5C7279" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>

                <span className="align-middle">{t("dashboard.My Profile")}</span>
            </DropdownItem>
            <DropdownItem
                tag="a"
                href="#"
                onClick={e => {
                    e.preventDefault();
                    props.logoutWithJWT();
                }}
            >
                <Icon.Power size={14} className="mr-50"/>
                <span className="align-middle">{t("Log Out")}</span>
            </DropdownItem>
        </DropdownMenu>
    )
}

class NavbarUser extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {isOpen: false};
    }

    toggle(){
        this.setState({isOpen: !this.state.isOpen})
    }
    
    render() {
        return (
            <ul className="nav navbar-nav navbar-nav-user">
                <Dropdown tag="li" className="dropdown-user nav-item" isOpen={this.state.isOpen} toggle={this.toggle.bind(this)}>
                    <DropdownToggle tag="a" className="nav-link dropdown-user-link px-0">
                        <div className="user-nav d-inline-flex">
                            <span className="user-icon">
                                <svg width="12" height="15" viewBox="0 0 12 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M11 14V12.6667C11 11.9594 10.719 11.2811 10.219 10.781C9.71885 10.281 9.04058 10 8.33333 10H3.66667C2.95942 10 2.28115 10.281 1.78105 10.781C1.28095 11.2811 1 11.9594 1 12.6667V14"
                                    stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
                                <path d="M6 7C7.65685 7 9 5.65685 9 4C9 2.34315 7.65685 1 6 1C4.34315 1 3 2.34315 3 4C3 5.65685 4.34315 7 6 7Z"
                                      stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
                                </svg>

                            </span>
                            <span className="user-name text-bold-600 d-none d-md-inline-block">
                                {this.props.userName}
                              </span>
                        </div>

                        <svg height="20" width="20" viewBox="0 0 20 20" aria-hidden="true" focusable="false" className="d-none d-md-inline-block">
                            <path fill='currentColor' d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"></path>
                        </svg>
                    </DropdownToggle>
                    <UserDropdown {...this.props} />
                </Dropdown>
            </ul>
        )
    }
}

const mapStateToProps = state => {
    return {
        user: state.authorizedUser.user
    }
}

export default connect(mapStateToProps, {logoutWithJWT})(NavbarUser)
