import axios from "axios"
import {apiErrorHandler} from "@utils/apiErrorHandler";
import {history} from "@src/history"
import notices from "../../../utils/notices";
import {setFetchMessage} from "../notification";
import {useTranslation} from "react-i18next";

export const getLoggedUserInfo = (require_authorized) => {
    return dispatch => {
        axios
            .get(`/api/me`)
            .then(response => {
                //temporary check untill server side remove status from error and return an actual error
                if(response.data.status === 401 && require_authorized){
                    history.push('/')
                    return false;
                }

                dispatch({
                    type: "FETCH_AUTHORIZED_USER_INFO",
                    user: response.data.data,
                });
                localStorage.setItem('user', JSON.stringify(response.data.data));

            }).catch((err) => {
            apiErrorHandler(err, require_authorized)
        })
    }
};

export const getAllUsers = (require_authorized) => {
    return dispatch => {
        axios
            .get(`/api/v1/all-users`)
            .then(response => {
                dispatch({
                    type: "FETCH_USERS",
                    users: response.data.data,
                });
            })
            .catch(err => console.log(err))
    }
};

export const updateProfileGeneralInfo = data => {
    return dispatch => {
        axios
            .put('/api/me', {
                fname: data.first_name,
                lname: data.last_name,
                lang: data.lang
            })
            .then(response => {
                dispatch(setFetchMessage(notices.success, 'success'));
                dispatch({
                    type: "FETCH_AUTHORIZED_USER_INFO",
                    user: response.data.data,
                });
            })
            .catch(err => {
                dispatch(apiErrorHandler(err));
                dispatch(setFetchMessage(err.response.data.message, 'error'));
            });

    }
}

export const updateProfileCompanyInfo = data => {
    return dispatch => {
        axios
            .put('/api/me/company', {
                name: data.company_name,
                address: data.address,
                zip: data.zip,
                city: data.city,
                country: data.country?.value
            })
            .then(response => {
                dispatch({
                    type: "FETCH_AUTHORIZED_USER_INFO",
                    user: response.data.data,
                });
                dispatch(setFetchMessage(notices.success, 'success'));
            })
            .catch(err => {
                // dispatch(apiErrorHandler(err));
                if(err)
                    dispatch(setFetchMessage(err.response.data.message, 'error'));
            });
    }
}


export const changeLanguage = (lang) => {

    return dispatch => {
        dispatch({
            type: "CHANGE_USER_LANGUAGE",
            payload: lang
        })
    }
}
export const unSubscribe = () => {

    return dispatch => {
        axios
            .post('/api/subscription/unsubscribe')
            .then(response => {
                dispatch({
                    type: "USER_UNSUBSCRIBE",
                })
                dispatch(setFetchMessage(response.message, 'success'));
                dispatch(getLoggedUserInfo())
            })
            .catch(err => {
                dispatch(setFetchMessage(err.response.data.message, 'error'));
            });

    }
}