import React, {useState} from "react"
import {Col, Collapse, Container, Nav, Navbar, NavbarToggler, Row} from "reactstrap"
import {connect} from "react-redux"
import NavbarUser from "./NavbarUser"
import {NavLink} from "react-router-dom";
import {useTranslation} from "react-i18next";

const Navbars = props => {
    const { t, i18n } = useTranslation();
    const [isOpen, setIsOpen] = useState(false);

    const toggle = () => setIsOpen(!isOpen);

    return (
        <header id='main-header'>
            <Container>
                <Row>
                    <Col>
                        <Navbar bg="light" variant="light" expand="lg" className="custom-navbar">
                            <div className="nav-bar-toggle d-lg-none" style={{marginLeft: '15px'}}>
                                <button type="button" className="toggle-menu-btn" onClick={toggle}>
                                    {!isOpen && <svg width="29" height="16" viewBox="0 0 29 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M0 15C0 15.5523 0.447715 16 1 16H28C28.5523 16 29 15.5523 29 15C29 14.4477 28.5523 14 28 14H1C0.447716 14 0 14.4477 0 15Z" fill="currentColor"/>
                                        <path d="M0 8C0 8.55228 0.447715 9 1 9H28C28.5523 9 29 8.55228 29 8C29 7.44772 28.5523 7 28 7H1C0.447716 7 0 7.44772 0 8Z" fill="currentColor"/>
                                        <path d="M0 1C0 1.55228 0.447715 2 1 2H28C28.5523 2 29 1.55228 29 1C29 0.447715 28.5523 0 28 0H1C0.447716 0 0 0.447715 0 1Z" fill="currentColor"/>
                                    </svg>}

                                    {isOpen && <svg width="18" height="17" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M4.82868 12.728L13.314 4.24268" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                        <path d="M4.82868 4.24262L13.314 12.7279" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                    </svg>
                                    }

                                </button>
                            </div>
                            

                            {props.user.email != '' && (
                                <NavbarUser
                                    confirm={props.confirm}
                                    userName={`${props.user.fname}`}
                                    loggedInWith={
                                        props.user !== undefined
                                            ? props.loggedInWith
                                            : null
                                    }
                                />
                            )}
                            <Collapse isOpen={isOpen} navbar>
                                <Nav className="mr-auto ml-0" style={{marginLeft: '0', marginRight: 'auto'}}>
                                    <li>
                                        <NavLink to='/products' activeClassName='in-page'>
                                            <svg width="14" height="14" viewBox="0 0 14 14" fill="none"
                                                 xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M5.25033 12.8334C5.57249 12.8334 5.83366 12.5722 5.83366 12.2501C5.83366 11.9279 5.57249 11.6667 5.25033 11.6667C4.92816 11.6667 4.66699 11.9279 4.66699 12.2501C4.66699 12.5722 4.92816 12.8334 5.25033 12.8334Z"
                                                    stroke="#5C7279" strokeLinecap="round" strokeLinejoin="round"/>
                                                <path
                                                    d="M11.6663 12.8334C11.9885 12.8334 12.2497 12.5722 12.2497 12.2501C12.2497 11.9279 11.9885 11.6667 11.6663 11.6667C11.3442 11.6667 11.083 11.9279 11.083 12.2501C11.083 12.5722 11.3442 12.8334 11.6663 12.8334Z"
                                                    stroke="#5C7279" strokeLinecap="round" strokeLinejoin="round"/>
                                                <path
                                                    d="M0.583008 0.583252H2.91634L4.47967 8.39409C4.53302 8.66265 4.67912 8.9039 4.89241 9.07559C5.1057 9.24729 5.37258 9.3385 5.64634 9.33325H11.3163C11.5901 9.3385 11.857 9.24729 12.0703 9.07559C12.2836 8.9039 12.4297 8.66265 12.483 8.39409L13.4163 3.49992H3.49967"
                                                    stroke="#5C7279" strokeLinecap="round" strokeLinejoin="round"/>
                                            </svg>
                                            {t("dashboard.My products & Orders")}
                                        </NavLink>
                                    </li>
                                </Nav>
                            </Collapse>
                        </Navbar>
                    </Col>
                </Row>
            </Container>

        </header>
    )
}

const mapStateToProps = state => {
    return {
        user: state.authorizedUser.user,
    }
}

export default connect(mapStateToProps, {})(Navbars);
